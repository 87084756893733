import { map, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { ZefApi } from '@shared/services/zef-api.service';
import { CartStateModel } from '@shared/states/cart.state';
import { BillingCartRequest, SCATokenResponse } from '@shared/models/billing.model';

@Injectable()
export class CartManager {
  public moveToNextStep: Subject<'buyer' | 'payment' | 'review'> = new Subject();

  private cart: CartStateModel = {};

  constructor(readonly za: ZefApi) {}

  public getCart(): CartStateModel {
    return { ...this.cart };
  }

  public setCart(cart: CartStateModel): Observable<CartStateModel> {
    const data = this.cartToRequest(cart);
    this.cart = { ...cart };

    if (!!cart.extra && !!cart.subscription && !!cart.parked) {
      return of({});
    }

    return this.za.post<CartStateModel>('billing/cart', data).pipe(
      map((response: CartStateModel) => {
        const cart_: any = !response
          ? {}
          : {
              ...cart,
              total: response.total || 0,
              taxName: response.taxName,
              taxPercentage: response.taxPercentage,
            };

        if (cart.sms && response.sms) {
          cart_.sms = {
            ...cart.sms,
            price: response.sms.price,
            total: response.sms.total,
            vat: response.sms.vat,
          };
        }

        return cart.parked && response.parked
          ? { ...cart_, parked: response.parked }
          : {
              ...cart_,
              extra: cart.extra && response.extra ? response.extra : cart.extra,
              subscription: !(cart.subscription && response.subscription)
                ? cart.subscription
                : { ...cart.subscription, billing: { ...response.subscription.billing } },
            };
      }),
      tap((newCart) => (this.cart = { ...newCart })),
    );
  }

  public checkout(cart: CartStateModel): Observable<SCATokenResponse> {
    const data = this.cartToRequest(cart);

    if (cart.subscription) {
      data.deleteFrom = cart.subscription.deleteFrom;
    }

    if (cart.smsRefill) {
      const { enabled, amount } = cart.smsRefill;
      this.za.post('billing/sms_reload', { enabled, amount }).toPromise();
    }

    return this.za.post<SCATokenResponse>('billing/checkout', data);
  }

  private cartToRequest(cart: CartStateModel): BillingCartRequest {
    const data: Partial<BillingCartRequest> = {};

    if (cart.parked) {
      data.parked = true;
    } else {
      if (cart.subscription) {
        const id = cart.subscription.plan.id;
        const period = cart.subscription.period;
        const options = cart.subscription.options;
        data.subscription = { id, options, period };
      }

      if (cart.extra) {
        data.extra = cart.extra;
      }

      if (cart.sms) {
        data.sms = { id: cart.sms.package.id };
      }
    }

    const date = new Date();
    data.cartDate = date.toISOString();

    return data as BillingCartRequest;
  }
}
